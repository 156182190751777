@import url(https://weloveiconfonts.com/api/?family=entypo|fontawesome);


/* entypo */
[class*="entypo-"]:before {
  font-family: 'entypo', sans-serif;
}

/* fontawesome */
[class*="fontawesome-"]:before {
  font-family: 'FontAwesome', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body{
	padding: 0;
	margin: 0;
	width: 100%;
    height: 100%;
    display: block;
}

body {
  min-height: 100%;
  min-height: -webkit-fill-available;
  background: #484848;
}
html {
  height: 100%;
  }

.topNav{
	width: 100%;
	height: 73px;
	display: block;
	border-bottom: 5px solid #484848;
	background: #484848;
	text-align: left;
	color: #fff;
	line-height: 74px;
	font-size: 70px;
    text-indent: 14px;
    letter-spacing: 7px;
}

.funlocallyPlus{
	text-align: right;
    float: right;
    font-size: 45px;
    padding-right: 10px;
}

.funlocallyGoldStar{
	color: #FFD700;
    text-shadow: 0px 0px 5px #ffd700;
}
.searchBox{
	display: block;
    float: left;
    clear: both;
    width: calc(100% - 145px);
    height: 59px;
    position: relative;
    top: 6px;
    left: 6px;
}

/* unvisited link */
.topNav a:link {
  text-decoration: none;
  color: #fff;
}

/* visited link */
.topNav a:visited {
  color: #fff;
}

/* mouse over link */
.topNav a:hover {
  text-shadow: 0px 0px 10px #fff;
}

/* selected link */
.topNav a:active {
  color: #fff;
}

.mapCalendarContainer{
	display: block;
	float: left;
	width: calc(100% - 325px);
	height: 100%;
}

.mapLocationContainer{
	display:block;
	float: left;
	clear:both;
	width: calc(100% - 0px);
	height: calc(100% - 155px);
}

.leaflet-container {
	height: calc(100% - 74px);
	width: 100%;
}

.calendarSelectorContainer{
	width: calc(100% - 0px);
	height: 75px;	
	display: block;
	float: left;
}

.calendarButton{
	width: 11.11%;
	height: 75px;
	display:block;
	float:left;
	border: 0;
}

.nextPreviousWeekButton{
	background: #484848;
	color: #fff;
	font-size: 20px;
	line-height: 75px;
}

.dayButton{
	background: #009ACD;
    color: #fff;
    border: 0;	
}

.dayButton > div{
	padding: 4px 0px 0px 0px;
}

.dayIsSelected{
	box-shadow: inset 0px 0px 50px #484848;
}

.dayData{
	line-height: 20px;
	font-size: 16px;
}

.eventScroll-container{
	position: absolute;
    right: 0;
	width: 320px;
	height: calc(100% - 78px);
	float: left;
	display: block;
	overflow-y: auto;
    overflow-x: hidden;
    border-left: 5px solid #484848;
    background: #999;
    clear:both;
}

.eventScroll-events-wrapper{
	width: 320px;
    display: block;
    height: calc(100% - 76px);
    overflow-y: auto;
    overflow-x: hidden;
}

.addButton{
	display: block;
    background: #dc143c;
    color: #fff;
    font-size: 30px;
    line-height: 75px;
    width: calc(100% - 0px);
    float: left;
    clear: both;
    bottom: 0;
    border: 0;
    box-shadow: inset 0px 0px 50px #484848;
    text-decoration: none;
    padding-bottom: 0px;
}

/* unvisited link */
.addButton a:link {
  text-decoration: none;
  color: #fff;
}

/* visited link */
.addButton a:visited {
  color: #fff;
}

/* mouse over link */
.addButton a:hover {
  text-shadow: 0px 0px 10px #fff;
}

/* selected link */
.addButton a:active {
  color: #fff;
}

.user{
	height: 40px;
    line-height: 40px;
    font-size: 12px;
    width: 140px;
    float: left;
    display: block;
    cursor: pointer;
}
.userImage{
	width: 35px;
	height: 35px;
	padding: 2.5px 0px 0px 2.5px;
	float: left;
	display: block;
}
.userName{
	width: 96px;
	height: 40px;
	padding-left: 5px;
	text-align: left;
	font-size: 12px;
	float: left;
	display: block;
}

.docTime{
	height: 20px;
    line-height: 16px;
    font-size: 12px;
    width: 83px;
    float: right;
    text-align: left;
    display: block;
    text-align: center;
    padding: 5px 0 0 0;
}
.docTitle{
	max-height: 50px;
    min-height: 30px;
    line-height: 25px;
    font-size: 20px;
	text-indent: 0px;
	width: 320px;
	float:left;
	text-align: center;
}

.badge{
	display: block;
	float: left;
    width: 320px;
    border-bottom: 1px solid #484848;
    background: #fff;
}  

.badgeImgContainer{
	width: 320px;
	display: flex;
  justify-content: center;
  align-items: center;
	line-height: 320px;
	background: #eee;
}

img.badgeImg{
	vertical-align: middle;
    max-height: 320px;
    max-width: 320px;
    display: block;
    margin: auto auto;
}

.description{
	padding: 2px 5px;	
	text-align: left;
	font-size: 12px;
}

.descriptionContainer{
	display: block;
    float: left;
    width: 320px;
    min-height: 90px;
}

.mobileNavButtonContainer{
	display: none;
}

.mobileView{
	display: none;
}

.supportLegend{
	display: block;
	width: 30px;
	height: 30px;
	background: blue;
}

.topNavSearchViewHide{
	display: none;
}

.topNavSearchViewShow{
	display: block;
}

.deskTopView{
	display: block;
}

.my-div-icon{	
	border-radius: 20% 20% 30% 0;
    border: solid #484848;
    border-width: 6px 6px 20px;
    width: 100px!important;
    height: 80px!important;
    background: #009acd;
    box-shadow: inset 0 0 15px #484848;
}

.leaflet-container .ticketStatus {
	height: 16px;
    font-size: 10px;
    background: RED;
    border-radius: 0% 0% 50% 50%;
    letter-spacing: 1px;
    width: 59px;
    margin: -6px auto 0px auto;
}

.leaflet-container .ticketStatus a{
	text-decoration: none;
	color: #fff;
}

.badgeTicketStatus {
	height: auto;
    font-size: 11px;
    background: RED;
    border-radius: 0% 0% 60% 60%;
    letter-spacing: 1px;
    width: 100px;
    color: #ffffff;
    float: right;
	padding-bottom: 10px;
}

.badgeTicketStatus a{
	text-decoration: none;
	font-size: 20px;
	color: #fff;
}

.ticketPrices{
	line-height: 14px;
    font-size: 12px;
}

@keyframes redToYellow {
  0%   {background-color:#FF8C00;}
  50%  {background-color:#FFDF00;}
  100% {background-color:#FF8C00;}
}

.ticket{
	clear: both;
    background: red;
    width: 100%;
    height: 50px;
}

.premiumIcon{
	border-radius: 30% 40% 40% 0;
    border: solid #8B4513;
    border-width: 6px 6px 20px;
    width: 100px!important;
    height: 80px!important;
    animation-name: redToYellow;
	animation-duration: 2s;
	animation-iteration-count: infinite;
    background: #FFD700;
    box-shadow: inset 0 0 15px #484848;
}
/*
.my-div-icon::after {
  position: absolute;
  content: '•';
  width: 60px;
  height: 60px;
  color: #484848;
  font-size: 60px;
  border-radius: 50%;
  font-weight: bold;
  top: 00%;
  left: 0%;
  margin-left: -1px;
  margin-top: -16px;
  background-color: linear-gradient(45deg, #007991 0%, #009ACD 100%);
}
*/

.map-label {
	color: #ffffff;
}
.map-label-title{
	position: absolute;
    top: 11px;
    left: 4px;
    font-size: 12px;
    text-align: left;
    line-height: 16px;
    color: #ffffff;
    font-weight: 600;
    padding: 0;
}

.premiumIcon .map-label-title{
	position: absolute;
    top: 11px;
    left: 4px;
    font-size: 14px;
    text-align: left;
    line-height: 16px;
    color: #000000;
    text-shadow: 0px 0px 10px #fff;
    font-weight: 600;
    padding: 0;
}


.map-label-whenisit{
	position: absolute;
    bottom: -19px;
    left: 0px;
    font-size: 13px;
}
.eventUrl{
	float: left;
	font-size: 9px;
	line-height: 10px;
	color: #0000ff;
	width: 100%;
	cursor: pointer;
}

.eventHandle{
	cursor: pointer;
}

.desktopBodyContainer {
	position: absolute;
    top: 78px;
    bottom: 0;
	width: 100%;
	height: calc(100% - 80px);
}

.premium{
	animation-name: redToYellow;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}

.front-marker{
	z-index: 500;
}
/*     */


@media only screen and (max-width: 900px) {
	body{
		overflow-x: hidden;
	}
	.eventScroll-events-wrapper{
		width: 100%;
		height: 100%;
		float: left;
		display: block;
		overflow: scroll;
	    overflow-x: hidden;
	    border-left: 0px solid #000;
	    background: #eee;
	}
	.addButton{
		display: none;
	}
	.leaflet-container{
		height: calc(100% - 230px);
		position: absolute !important;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	}
	.mapCalendarContainer{
		display: block;
		float: left;
		width: 100%;
		height: calc(100% - 178px);
	    position: relative;
	    top: 0px;
	}
	
	.mapLocationContainer{
		display:block;
		float: left;
		clear:both;
		width: calc(100% - 0px);
		height: calc(100% - 230px);
	}
	.eventScroll-container{
		width: 100%;
		height: calc(100% - 150px);
		float: left;
		display: block;
		overflow: hidden;
	    overflow-x: hidden;
	    border-left: 0px solid #000;
	    background: #eee;
	}
	.badge{
		display: block;
		float: left;
	    width: 100%;
	    border-bottom: 1px solid #eee;
	    background: #fff;
	} 
	.premium{
		animation-name: redToYellow;
		animation-duration: 2s;
		animation-iteration-count: infinite;
	}
	.docTitle{
		font-size: 20px;
		text-indent: 0px;
		width: 100%;
		float:left;
	}
	.badgeImgContainer{
		width: 100%;
		display: block;	
		 justify-content: center;
		 align-items: center;
		float: left;
		line-height: 320px;
		background: #eee;
	}
	
	.descriptionContainer{
		display: block;
	    float: left;
	    width: 100%;
	    min-height: 90px;
	}
	
	.calendarSelectorContainer{
		width: calc(100% - 0px);
		height: 75px;	
		display: block;
		float: left;
		position: fixed;
		bottom: 75px;
	}
	.mobileNavButtonContainer{
		width: calc(100% - 0px);
		height: 75px;	
		display: block;
		float: left;
		position: fixed;
		bottom: 0px;
	}
	
	.mobileNavButton{
		display: block;
		height: 75px;
		width: 44.44%;
		background: #484848;
		float: left;
		line-height: 75px;
		font-size: 35px;
		color: #ffffff;
		text-decoration: none;
	}
	
	.add-event-button{
		width: 11.11%;
		background: #dc143c;
		box-shadow: inset 0px 0px 20px #484848;
	}
	
	.mobileLinkButton{
		display: block;
		height: 75px;
		width: 9.62%;
		background: #484848;
		float: left;
		line-height: 75px;
		font-size: 35px;
		color: #ffffff;
		
		background: #000000;
		  background-image: -webkit-linear-gradient(top, #000000, #484848);
		  background-image: -moz-linear-gradient(top, #000000, #484848);
		  background-image: -ms-linear-gradient(top, #000000, #484848);
		  background-image: -o-linear-gradient(top, #000000, #484848);
		  background-image: linear-gradient(to bottom, #000000, #484848);
	}
	.hideSection{
		display: none;
	}
	.showSection{
		display: block;
	}
	
	.navButtonSelected{
		box-shadow: inset 0px 0px 50px #000;
		text-shadow: 0px 0px 10px #ffffff;
	}
	.mobileView{
		display: block;
	}
	
	.topNavSearchViewHide{
		display: none;
	}
	
	.topNavSearchViewShow{
		display: block;
	}

	.deskTopView{
		display: none;
	}
	
	.mobileBodyContainer {
		position: absolute;
	    top: 0;
	    bottom: 0;
	    width: 100%;
	    height: calc(100% - 50px);
	}
}

@media only screen and (max-width: 600px) {
	.eventScroll-container{
		width: 100%;
		height: calc(100% - 110px);
		float: left;
		display: block;
		overflow: hidden;
	    overflow-x: hidden;
	    border-left: 0px solid #000;
	    background: #eee;
	}
	.topNav{
		width: 100%;
		height: 45px;
		display: block;
		border-bottom: 5px solid #484848;
		background: #484848;
		text-align: left;
		color: #ffffff;
		line-height: 45px;
		font-size: 35px;
	    text-indent: 14px;
	    letter-spacing: 4px;
	}
	.leaflet-container{
		height: calc(100% - 160px);
		position: absolute !important;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	}
	.calendarSelectorContainer{
		width: calc(100% - 0px);
		height: 50px;	
		display: block;
		float: left;
		position: fixed;
		bottom: 60px;
	}
	
	.calendarButton{
		width: 11.11%;
		height: 50px;
		display:block;
		float:left;
	}
	
	.nextPreviousWeekButton{
		background: #484848;
		color: #fff;
		font-size: 20px;
		line-height: 50px;
	}
	.dayData{
		line-height: 11px;
		font-size: 12px;
	}
	.dayButton{
		background: #009ACD;
		color: #fff;
	}
	
	.dayButton > div{
		padding: 4px 0px 0px 0px;
	}
	
	.dayIsSelected{
		box-shadow: inset 0px 0px 30px #484848;
	}
	.mapCalendarContainer{
		display: block;
		float: left;
		width: 100%;
		height: calc(100% - 110px);
	    position: relative;
	    top: 0px;
	}
	.mobileNavButtonContainer{
		width: calc(100% - 0px);
		height: 60px;	
		display: block;
		float: left;
		position: fixed;
		bottom: 0;
	}
	
	.mobileNavButton{
		display: block;
		height: 60px;
		width: 50%;
		background: #484848;
		float: left;
		line-height: 60px;
		font-size: 25px;
		color: #ffffff;
	}
	.navButtonSelected{
		box-shadow: inset 0px 0px 50px #000;
		text-shadow: 0px 0px 10px #ffffff;
	}
	
	.topNavSearchViewHide{
		display: none;
	}
	
	.topNavSearchViewShow{
		display: block;
	}
	
	.searchBox{
		display: block;
	    float: left;
	    clear: both;
	    width: calc(100% - 115px);
	    height: 38px;
	    position: relative;
	    top: 3px;
	    left: 3px;
	}
	
	.mobileNavButton{
		display: block;
		height: 75px;
		width: 44.44%;
		background: #484848;
		float: left;
		line-height: 60px;
		font-size: 25px;
		color: #ffffff;
		text-decoration: none;
	}
	
	.add-event-button{
		width: 11.11%;
		background: #dc143c;
	}
	
	.funlocallyPlus{
		text-align: right;
	    float: right;
	    font-size: 30px;
	    padding-right: 10px;
	}
}